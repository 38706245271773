import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Ad from './Ad';

interface ResponsiveAdProps {
  position?: 'left' | 'right';
}

const ResponsiveAd: React.FC<ResponsiveAdProps> = ({ position }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <Ad dataAdSlot="1264546093" />; // Horizontal Bottom
  }

  if (position === 'left') {
    return <Ad dataAdSlot="7850135747" />; // Vertical Left
  }

  if (position === 'right') {
    return <Ad dataAdSlot="2524850972" />; // Vertical Right
  }

  return null;
};

export default ResponsiveAd;
