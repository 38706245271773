import React, { useState, useMemo } from 'react';
import { 
  Container, Typography, TextField, Select, MenuItem, 
  Button, Paper, Box, InputAdornment, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
  FormControl, InputLabel, Grid, ThemeProvider, createTheme, CssBaseline, 
  IconButton, Snackbar, Tooltip, useTheme
} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import StarIcon from '@mui/icons-material/Star';
import CalculateIcon from '@mui/icons-material/Calculate';
import ResponsiveAd from './ResponsiveAd';

type Order = 'asc' | 'desc';

interface ConversionResult {
  fraction: string;
  deviation: number;
  deviationPercent: number;
  denominator: number;
}

interface ResultsTableProps {
  results: ConversionResult[];
  orderBy: keyof ConversionResult;
  order: Order;
  onSort: (property: keyof ConversionResult) => void;
  exactValue: number;
}

interface MeasurementBarProps {
  fraction: number;
  exactValue: number;
  maxDeviation: number;
  isExactValue?: boolean;
}

const MeasurementBar: React.FC<MeasurementBarProps> = ({ 
  fraction, 
  exactValue, 
  maxDeviation,
  isExactValue = false
}) => {
  const deviation = fraction - exactValue;
  const relativeDeviation = deviation / maxDeviation;
  
  // Use the relative deviation to determine the shift
  const shift = isExactValue ? 0 : relativeDeviation * 45; // 45% gives us some margin on either side
  
  const barColor = isExactValue ? '#4caf50' : (deviation < 0 ? '#3f51b5' : '#f50057');

  return (
    <Tooltip title={`${fraction.toFixed(6)}" (Deviation: ${deviation.toFixed(6)}")`}>
      <Box sx={{ width: '100%', height: 20, backgroundColor: '#e0e0e0', position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: `calc(50% + ${shift}% - 2.5%)`,
            width: '5%',
            height: '100%',
            backgroundColor: barColor,
          }}
        />
      </Box>
    </Tooltip>
  );
};

const ResultsTable: React.FC<ResultsTableProps> = ({ results, orderBy, order, onSort, exactValue }) => {
  const theme = useTheme();

  const closestMatchIndex = results.reduce((closest, current, index) => {
    return Math.abs(current.deviation) < Math.abs(results[closest].deviation) ? index : closest;
  }, 0);

  const maxDeviation = Math.max(...results.map(r => Math.abs(r.deviation)));

  return (
    <TableContainer component={Paper} sx={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'fraction'}
                direction={orderBy === 'fraction' ? order : 'asc'}
                onClick={() => onSort('fraction')}
              >
                Fraction
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'deviation'}
                direction={orderBy === 'deviation' ? order : 'asc'}
                onClick={() => onSort('deviation')}
              >
                Deviation (in)
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'deviationPercent'}
                direction={orderBy === 'deviationPercent' ? order : 'asc'}
                onClick={() => onSort('deviationPercent')}
              >
                Deviation (%)
              </TableSortLabel>
            </TableCell>
            <TableCell>Visualization</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell padding="checkbox">
              <Tooltip title="Exact conversion value" arrow>
                <CalculateIcon fontSize="small" color="success" />
              </Tooltip>
            </TableCell>
            <TableCell>{exactValue.toFixed(6)}"</TableCell>
            <TableCell>0.000000"</TableCell>
            <TableCell>0.0000%</TableCell>
            <TableCell>
              <MeasurementBar 
                fraction={exactValue} 
                exactValue={exactValue} 
                maxDeviation={maxDeviation}
                isExactValue={true}
              />
            </TableCell>
          </TableRow>
          {results.map((result, index) => (
            <TableRow key={index}>
              <TableCell padding="checkbox">
                {index === closestMatchIndex && <StarIcon color="primary" fontSize="small" />}
              </TableCell>
              <TableCell>{result.fraction}</TableCell>
              <TableCell>{result.deviation.toFixed(6)}"</TableCell>
              <TableCell>{result.deviationPercent.toFixed(4)}%</TableCell>
              <TableCell>
                <MeasurementBar 
                  fraction={exactValue + result.deviation} 
                  exactValue={exactValue}
                  maxDeviation={maxDeviation}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function App() {
  const [metricValue, setMetricValue] = useState<number>(0);
  const [metricUnit, setMetricUnit] = useState<string>('mm');
  const [tolerance, setTolerance] = useState<number>(0.1);
  const [results, setResults] = useState<ConversionResult[]>([]);
  const [orderBy, setOrderBy] = useState<keyof ConversionResult>('deviation');
  const [order, setOrder] = useState<Order>('asc');
  const [maxPrecision, setMaxPrecision] = useState<number>(64);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const convertToFractionalInches = (mm: number, tolerance: number): ConversionResult[] => {
    const inches = mm / 25.4;
    const results: ConversionResult[] = [];

    for (let denominator = 1; denominator <= 64; denominator *= 2) {
      for (let numerator = Math.floor((inches - Math.abs(tolerance)) * denominator); 
           numerator <= Math.ceil((inches + Math.abs(tolerance)) * denominator); 
           numerator++) {
        const fraction = numerator / denominator;
        const deviation = fraction - inches;
        const deviationPercent = (deviation / inches) * 100;
        
        if (Math.abs(deviation) <= Math.abs(tolerance)) {
          let formattedFraction;
          if (numerator >= denominator) {
            const wholePart = Math.floor(numerator / denominator);
            const fractionalPart = numerator % denominator;
            formattedFraction = fractionalPart === 0 ? 
              `${wholePart}"` : 
              `${wholePart} ${fractionalPart}/${denominator}"`;
          } else {
            formattedFraction = `${numerator}/${denominator}"`;
          }
          results.push({
            fraction: formattedFraction,
            deviation: deviation,
            deviationPercent: deviationPercent,
            denominator: denominator
          });
        }
      }
    }

    return results;
  };

  const handleConvert = () => {
    let value = metricValue;
    if (metricUnit === 'cm') value *= 10;
    if (metricUnit === 'm') value *= 1000;
    const convertedResults = convertToFractionalInches(value, tolerance);
    setResults(convertedResults);
  };

  const handleSort = (property: keyof ConversionResult) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredResults = useMemo(() => {
    return results.filter(result => result.denominator <= maxPrecision);
  }, [results, maxPrecision]);

  const sortedResults = useMemo(() => {
    const compare = (a: ConversionResult, b: ConversionResult) => {
      if (Math.abs(b[orderBy] as number) < Math.abs(a[orderBy] as number)) {
        return order === 'asc' ? 1 : -1;
      }
      if (Math.abs(b[orderBy] as number) > Math.abs(a[orderBy] as number)) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    };

    const uniqueResults = filteredResults.reduce((acc, current) => {
      const x = acc.find(item => item.fraction === current.fraction);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as ConversionResult[]);

    const sortedUniqueResults = uniqueResults.sort(compare);

    return {
      smaller: sortedUniqueResults.filter(r => r.deviation <= 0),
      larger: sortedUniqueResults.filter(r => r.deviation > 0)
    };
  }, [filteredResults, order, orderBy]);

  const exactValue = useMemo(() => {
    let value = metricValue;
    if (metricUnit === 'cm') value *= 10;
    if (metricUnit === 'm') value *= 1000;
    return value / 25.4; // Convert mm to inches
  }, [metricValue, metricUnit]);

  const handleCopyToClipboard = () => {
    const resultText = sortedResults.smaller.concat(sortedResults.larger)
      .map(r => `${r.fraction} (${r.deviation.toFixed(6)}", ${r.deviationPercent.toFixed(4)}%)`)
      .join('\n');
    navigator.clipboard.writeText(resultText).then(() => {
      setSnackbarOpen(true);
    });
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: '#3f51b5',
          },
          secondary: {
            main: '#f50057',
          },
        },
        typography: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: 12,
              },
            },
          },
        },
      }),
    [darkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        background: darkMode 
          ? 'linear-gradient(145deg, #1a237e 0%, #121212 100%)' 
          : 'linear-gradient(145deg, #e8eaf6 0%, #ffffff 100%)',
      }}>
        <Container maxWidth="xl" sx={{ 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: 4,
          pb: 4,
        }}>
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" component="h1" fontWeight="bold" color={darkMode ? 'white' : 'primary'}>
              Metric to Imperial Converter
            </Typography>
            <IconButton onClick={() => setDarkMode(!darkMode)} color="inherit" sx={{ ml: 2 }}>
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
          <Box display="flex">
            <Box display={{ xs: 'none', md: 'block' }} width="160px" mr={2}>
              <ResponsiveAd position="left" />
            </Box>
            <Box flexGrow={1}>
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Metric Value"
                      type="number"
                      value={metricValue}
                      onChange={(e) => setMetricValue(Number(e.target.value))}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              value={metricUnit}
                              onChange={(e) => setMetricUnit(e.target.value as string)}
                            >
                              <MenuItem value="mm">mm</MenuItem>
                              <MenuItem value="cm">cm</MenuItem>
                              <MenuItem value="m">m</MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Tolerance (±inches)"
                      type="number"
                      inputProps={{ step: "0.01" }}
                      value={tolerance}
                      onChange={(e) => setTolerance(Number(e.target.value))}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Maximum Precision</InputLabel>
                      <Select
                        value={maxPrecision}
                        label="Maximum Precision"
                        onChange={(e) => setMaxPrecision(Number(e.target.value))}
                      >
                        <MenuItem value={2}>1/2</MenuItem>
                        <MenuItem value={4}>1/4</MenuItem>
                        <MenuItem value={8}>1/8</MenuItem>
                        <MenuItem value={16}>1/16</MenuItem>
                        <MenuItem value={32}>1/32</MenuItem>
                        <MenuItem value={64}>1/64</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={handleConvert} fullWidth size="large">
                      Convert
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" component="h2" gutterBottom fontWeight="medium" color={darkMode ? 'white' : 'primary'}>
                    Smaller Results
                  </Typography>
                  <ResultsTable 
                    results={sortedResults.smaller} 
                    orderBy={orderBy} 
                    order={order} 
                    onSort={handleSort}
                    exactValue={exactValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" component="h2" gutterBottom fontWeight="medium" color={darkMode ? 'white' : 'primary'}>
                    Larger Results
                  </Typography>
                  <ResultsTable 
                    results={sortedResults.larger} 
                    orderBy={orderBy} 
                    order={order} 
                    onSort={handleSort}
                    exactValue={exactValue}
                  />
                </Grid>
              </Grid>
              <Button onClick={handleCopyToClipboard} variant="outlined" sx={{ mt: 4, alignSelf: 'center' }}>
                Copy Results to Clipboard
              </Button>
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} width="160px" ml={2}>
              <ResponsiveAd position="right" />
            </Box>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }} mt={2}>
            <ResponsiveAd />
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="Results copied to clipboard"
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
